<template>
  <div class="mt-16 min-w-screen w-10/12 mx-auto">
    <div
      v-if="layout == 'vonblon' || layout == 'pw'"
      class="container ml-8 text-sm pb-10 flex flex-wrap"
    >
      <div class="w-1/3">
        <p class="mb-5">
          VONBLON MASCHINEN GMBH
          <br />Landstraße 28 <br />6714 Nüziders <br />Österreich
          <br />
          <a href="tel:0043555263868" class="text-red">Tel. +43 5552 63868</a>
          <br />Fax +43 5552 66745
          <br />
          <a href="mailto:office@vonblon.cc" class="text-red"
            >office@vonblon.cc</a
          >
        </p>
      </div>

      <div v-if="layout == 'vonblon'" class="w-2/3">
        <h2 class="text-lg">Wintermiete, Eventmiete, Alpmiete</h2>
        <p class="mb-1 leading-tight">
          Wir bieten unsere Fahrzeuge mit dazupassenden Anhänger oder Spurwalze
          auch als Saison Miete für Winter oder Sommer mit Raupen oder Räder an.
          Ein grosses Kontingent an POLARIS Quads, ATV, UTV und Motorschlitten
          steht zur Verfügung.
        </p>
        <p class="mb-1 leading-tight">
          Die Mietgeräte werden gerne für Seilbahn, Hüttenwirtschaft oder Jagd
          eingesetzt, auch für Höhenbaustellen können wir Polaris Raupenquads
          zur Verfügung stellen.
        </p>
        <p class="mb-1 leading-tight">
          Ranger EV für den Tourismus (leise, kräftig, belastbar) - Reichweite
          durch Lithium Umbau.
        </p>
        <p class="mb-1 leading-tight">
          ATV mit Anhänger zugelassen für die Wegepflege Ideales Fahrzeuggespann
          mit 122 cm Breite.
        </p>
        <p class="mb-1 leading-tight">
          Ranger Diesel Allrad, sparsam und betriebssicher (Motorbremse,
          Allradbremse).
        </p>
        <p class="mb-1 leading-tight text-xs">
          Preise sind ohne Haftpflichtversicherung und Anmeldung (wenn
          notwendig) ab Lager Vonblon, Fahrzeuge sind vom Mieter zu versichern.
          Führerschein B ist Grundvoraussetzung. Abrechnung nach Zeit / Stunden
          lt. Nutzung. Nähere Infos unter 0043 5552 63868
        </p>
      </div>

      <div v-if="false" class="w-1/3">
        <p class="mb-5">
          VONBLON POLARIS Salzburgerstrass
          <br />Service-Stützpunkt, Helmut Hillebrand <br />Salzburgerstrasse
          644 <br />5084 Grossgmain <br />Österreich
          <br />
          <a href="tel:004366488467787" class="text-red"
            >Tel. +43 664 88467787</a
          >
          <br />
          <a href="mailto:office@vonblon-polaris-salzburg.at" class="text-red"
            >office@vonblon-polaris-salzburg.at</a
          >
        </p>
      </div>

      <div v-if="false" class="w-1/2">
        <p class="mb-5">
          Servicestützpunkt Bez. Kitzbühel
          <br />Christian Resch - mobiler Service
          <br />
          <a href="tel:00436642144923" class="text-red">Tel. +43 664 2144923</a>
        </p>
        <p class="mb-5">
          <br />Automower Kundendienst Nüziders
          <br />
          <a href="tel:0043555231607" class="text-red">Tel. +43 5552 31607</a>
        </p>
        <p class="mb-5">
          AUTOMOWER - SHOP - LUSTENAU
          <br />VONBLON MASCHINEN GmbH <br />Maria-Theresien-Strasse 77
          <br />6890 Lustenau
          <br />
          <a href="tel:00436767809674" class="text-red">Tel. +43 676 7809674</a>
        </p>
        <p>
          <a
            class="text-red font-bold"
            href="https://www.vonblon.cc/vonblon/datenschutzerklaerung-cookies/"
            >Datenschutz- und Nutzungsbedingungen</a
          >
        </p>
      </div>
    </div>

    <div v-else class="container ml-8 mb-5 text-sm pb-10 flex flex-wrap">
      <div class="w-1/2">
        <p>
          <a href="https://portable-winch.eu" class="font-bold"
            >Portable Winch VONBLON Maschinen GmbH</a
          >
          <br />
          <a href="mailto:office@portable-winch.eu">office@portable-winch.eu</a
          >, Landstraße 28, 6714 Nüziders, Österreich, Tel.
          <a href="tel:+43 5552 63868">+43 5552 63868</a>, Fax
          <a href="tel:+43 5552 66745">+43 5552 66745</a>
          <br />verantwortlich für: <br />Import/Vertrieb Portable Winch
          Kabelverlegungsmaschinen für ganz Europa <br />Import/Vertrieb
          Portable Winch Spill-Seilwinden für Mittel- und Osteuropa
        </p>
      </div>
      <div class="w-1/2">
        <p class="text-right">
          <a href="https://portable-winch.eu/reseller">Unsere Händler</a> |
          <a href="https://portable-winch.eu/datenschutz">Datenschutz</a> |
          <a href="https://portable-winch.eu/impressum">Impressum</a> |
          <a href="https://portable-winch.eu/agb">AGB</a>
          <br />© 2020 Portable-Winch.eu
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["layout"],
}
</script>
