import Vue from "vue";
import Vuex from "vuex";

import EventBus from "../utilities/event_bus";
import Api from "../utilities/api";

// Vue.use(VueAxios, axios)
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    products: [],
    headlines: [],
    rental_periods: [],
    tags: [],
    messages: [],
  },
  getters: {},
  mutations: {
    set_products(state, data) {
      state.products = data;
    },
    set_headlines(state, data) {
      state.headlines = data;
    },
    set_rental_periods(state, data) {
      state.rental_periods = data;
    },
    set_tags(state, data) {
      state.tags = data;
    },
    set_messages(state, data) {
      state.messages = data;
    },
  },
  actions: {
    async fetchStartup(context) {
      return await Api().get("startup")
        .then((response) => {
          context.commit("set_products", response.data.products);
          context.commit("set_headlines", response.data.headlines);
          context.commit("set_tags", response.data.tags);
          context.commit("set_rental_periods", response.data.rental_periods);
          context.commit("set_messages", response.data.messages);
        });
    },
    send_order(context, fields) {
      console.log("send_order", fields);
      return Api().post("customer_requests", fields)
        .then((request) => {
          console.log(request);
          EventBus.$emit("order-successful", request);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
});
