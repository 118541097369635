<template>
  <header data-aos="fade-in" class="ml-3 mt-3 fixed">
    <div
      class="flex sm:flex sm:justify-between sm:items-center sm:px-4 sm:py-3"
    >
      <div class="justify-between px-4 py-3 sm:p-0 mr-10">
        <span xv-if="layout == 'vonblon'">Mietgeräte Rental Service</span>
        <img
          v-if="layout == 'vonblon'"
          src="~images/pw.logo.svg"
          class="h-16 max-h-100"
        />
        <img
          v-if="layout == 'pw'"
          src="~images/vonblon.power.svg"
          class="h-16 max-h-100"
        />
      </div>
      <jubilaeum v-if="true || layout == 'vonblon'"></jubilaeum>
    </div>
  </header>
</template>

<script>
import Jubilaeum from "./Jubilaeum.vue"

export default {
  props: ["layout", "tags", "search_visible"],
  components: {
    Jubilaeum,
  },
}
</script>
