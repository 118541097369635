<template>
  <div
    id="app"
    class="w-full absolute"
    :class="{
      'bg-vonblon-blue': customer_layout == 'vonblon',
      'bg-vonblon-white': customer_layout == 'pw',
    }"
  >
    <header-section
      :layout="customer_layout"
      class="z-10 fixed"
      :search_visible="false"
      @search="search"
    ></header-section>
    <splash-slider
      v-if="headlines.length > 0"
      :delay="7000"
      :slides="headlines"
    ></splash-slider>
    <rental-index :layout="customer_layout"></rental-index>
    <footer-section :layout="customer_layout"></footer-section>
  </div>
</template>

<script>
import HeaderSection from "./components/HeaderSection.vue"
import FooterSection from "./components/FooterSection.vue"

import SplashSlider from "./components/SplashSlider.vue"
import RentalIndex from "./components/RentalIndex.vue"

import { mapState } from "vuex"

import scrollit from "./utilities/scrollit"

export default {
  data() {
    return {
      // customer_layout: 'vonblon' // pw or vonblon
    }
  },
  computed: {
    ...mapState(["headlines"]),
    customer_layout() {
      if (
        location.hostname.match(/polaris-miete/) ||
        location.hostname.match(/quat/) ||
        location.hostname.match(/rental.vonblon.cc/)
      ) {
        return "vonblon"
      } else {
        // return "vonblon"
        return "pw"
      }
    },
  },
  methods: {
    search(v) {
      console.log(v)
    },
  },
  async mounted() {
    await this.$store.dispatch("fetchStartup")
    this.$gtm.enable(true)
  },
  components: {
    HeaderSection,
    SplashSlider,
    RentalIndex,
    FooterSection,
  },
}
</script>
