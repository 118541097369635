/* eslint no-console: 0 */

// require("@rails/ujs").start()
import "stylesheets/application";
const images = require.context("../images", true);

import AOS from "aos";
import "aos/dist/aos.css";

import Vue from "vue";

let gtm_tag = "";
if (
  location.hostname.match(/polaris-miete/) ||
  location.hostname.match(/quat/) ||
  location.hostname.match(/rental.vonblon.cc/)
) {
  gtm_tag = "GTM-T3R9LVL";
} else {
  gtm_tag = "GTM-5VJ3TPJ";
}

import VueGtm from "vue-gtm";
// import { createGtm } from "vue-gtm";

Vue.use(VueGtm, {
  id: gtm_tag,
  defer: false,
  enabled: true,
  debug: process.env.NODE_ENV === "development",
  loadScript: false,
  trackOnNextTick: false,
});

// import VuePlyr from 'vue-plyr'

import autofocus from "vue-autofocus-directive";
Vue.directive("autofocus", autofocus);

import store from "stores";
import App from "Rental.vue";

import EventBus from "../utilities/event_bus";

document.addEventListener("DOMContentLoaded", () => {
  window.EventBus = EventBus;
  AOS.init();
  const app = new Vue({
    store,
    render: (h) => h(App),
  });
  app.$mount();
  document.body.appendChild(app.$el);
});
