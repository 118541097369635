import Axios from 'axios'

export default () => {

  var token = document.getElementsByName('csrf-token')[ 0 ].getAttribute('content')
  return Axios.create({
    baseURL: '/api/v1',
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'Credentials': 'same-origin',
      'Accept': 'application/json',
      'X-CSRF-Token': token
    }
  })
}
