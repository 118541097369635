<template>
  <div
    class="modal-frame z-10 fixed inset-0 overflow-auto items-contern flex flex-col h-auto xjustify-center align-middle"
    @click="close"
    style="background-color: rgba(0,0,0,0.5)"
  >
    <div
      name="modal-content"
      class="container shadow-lg mx-auto relative w-1/1 p-2 pb-4 md:p-8 w-full md:w-10/12 xl:w-8/12 max-w-3xl"
      :class="css_class"
      @click.stop
    >
      <button
        aria-label="close"
        class="absolute top-0 right-0 text-xl text-gray-500 mr-4"
        @click.prevent="close"
      >
        <h1 class="text-5xl font-bold">&times;</h1>
      </button>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    css_class: {
      type: String,
    },
  },
  methods: {
    close() {
      this.$emit("close")
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-frame::after,
.modal-frame::before {
  content: "";
  margin: auto;
}
</style>
