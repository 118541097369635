<template>
  <div
    class="container mx-auto w-12/12 lg:w-10/12 xl:w-9/12 min-h-screen min-w-screen mb-5"
  >
    <a id="rental-index"></a>

    <ordering
      v-show="display_details"
      @close="close_details"
      :product="product"
      :layout="layout"
    ></ordering>

    <transition-group
      name="fade"
      tag="div"
      class="pt-56 md:pl-10 xmx-auto flex flex-wrap -mx-2 box-border"
    >
      <div
        v-for="(product, idx) in products"
        :key="product.id"
        class="product-in-index overflow-hidden cursor-pointer relative box-border mr-2 w-1/1 md:w-1/2 lg:w-1/3 inline-block mb-2 shadow-lg"
        style="height: 320px"
        data-aos="fade-in"
        :data-aos-delay="50 * idx"
        @click.prevent="show_details(product)"
      >
        <img
          class="product-in-index-image object-cover w-full h-full transform hover:scale-110 ease-in-out duration-500"
          :src="product.thumb"
        />
        <div v-if="product.title" class="header absolute top-0 w-full">
          <h1 class="mt-3 mx-3 text-white text-2xl font-normal leading-none">
            {{ product.title }}
          </h1>
        </div>
        <div
          v-if="product.teaser || product.price"
          class="footer absolute bottom-0 w-full"
          style="height: 140px;"
        >
          <div class="absolute bottom-0 ml-3 mb-2">
            <div v-if="product.price" class="-mb-2 text-white">
              <span
                v-if="product.price > 0 && product.price_prefix"
                class="text-base"
                >{{ product.price_prefix }}</span
              >
              <span class="text-4xl">{{ product.price }} €</span>
              <span
                v-if="product.price > 0 && product.price_suffix"
                class="text-base"
                >{{ product.price_suffix }}</span
              >
              <span
                v-if="product.price > 0 && product.price_with_tax <= 0"
                class="text-sm font-thin"
                >excl. Mwst.</span
              >
              <span
                v-if="product.price > 0 && product.price_with_tax > 0"
                class="text-sm font-thin"
                >incl. Mwst.</span
              >
            </div>
            <p class="text-white leading-tight text-md">{{ product.teaser }}</p>
          </div>
        </div>
      </div>
      <div v-if="false">{{ products }}</div>
    </transition-group>
    <div
      class="overflow-hidden cursor-pointer relative box-border mr-2 w-1/1 xmd:w-1/2 xlg:w-1/3 inline-block mb-2"
      data-aos="fade-in"
    >
      <div
        v-for="message in messages"
        class="mx-8 my-6"
        :key="message.id"
        v-html="message.content"
      ></div>
    </div>
  </div>
</template>

<script>
import Ordering from "./Ordering.vue"
import { mapState } from "vuex"

export default {
  data() {
    return {
      display_details: false,
      product: {},
    }
  },
  props: ["layout"],
  methods: {
    close_details() {
      this.display_details = false

      let cl = document.querySelector("body").classList
      console.log(cl)
      if (cl.contains("overflow-hidden")) {
        console.log("overflow set")
        cl.remove("overflow-hidden")
      }
    },
    show_details(product) {
      this.display_details = true
      this.product = product
      this.display_product = product.id

      this.$gtm.trackEvent({
        event: null,
        category: "DisplayProductDetail",
        label: product.title,
        value: product.price,
      })

      let cl = document.querySelector("body").classList
      console.log(cl)
      if (!cl.contains("overflow-hidden")) {
        cl.add("overflow-hidden")
      }
      console.log("show details")
    },
  },
  computed: {
    ...mapState(["products", "messages"]),
  },
  components: {
    Ordering,
  },
}
</script>

<style scoped>
.header {
  height: 120px;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.7037993735455451) 0%,
    rgba(114, 114, 114, 0) 100%
  );
}
.footer {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.6037993735455451) 0%,
    rgba(114, 114, 114, 0) 100%
  );
}
.fade-enter-active {
  transition: all 0.3s ease;
}
.fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
</style>

<style>
h1 {
  font-size: 1.5rem;
}
h2 {
  font-size: 1.3rem;
}
h3 {
  font-size: 1.1rem;
}
</style>
