<template>
  <modal
    v-show="display_details"
    @close="close_ordering"
    @show-order-form="change_display_order_form"
    :css_class="layout === 'vonblon' ? 'bg-vonblon-blue' : 'bg-vonblon-white'"
    :layout="layout"
  >
    <product-details
      v-if="!show_order_form && !order_send_success"
      :product="product"
      @show-order-form="change_display_order_form"
      :layout="layout"
    ></product-details>

    <order-form
      v-if="show_order_form && !order_send_success"
      :product="product"
      @show-order-form="change_display_order_form"
      :layout="layout"
    ></order-form>

    <div v-if="order_send_success" class="mx-auto">
      <div class="flex flex-wrap -mx-3">
        <div>
          <h1 class="mt-4 text-3xl">Vielen Dank für Ihre Anfrage</h1>
          <p class="mt-4">
            Wir haben Ihre Daten erhalten. Der nächste Schritt kommt von unserer
            Seite. Wir melden uns umgehend mit entsprechend detailierten
            Informationen.
          </p>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "./Modal.vue"
import ProductDetails from "./ProductDetails.vue"
import OrderForm from "./OrderForm.vue"

export default {
  data() {
    return {
      show_order_form: false,
      order_send_success: false,
    }
  },
  props: ["layout", "product", "display_details"],
  methods: {
    close_ordering() {
      this.close_details()
      this.$emit("close")
    },
    change_display_order_form() {
      console.log("change_display_order_form")
      this.show_order_form = !this.show_order_form
    },
    close_details() {
      this.show_order_form = false
      this.order_send_success = false
    },
  },
  mounted() {
    let that = this
    EventBus.$on("order-successful", (message) => {
      this.show_order_form = false
      this.order_send_success = true
    })
  },
  components: {
    Modal,
    ProductDetails,
    OrderForm,
  },
}
</script>
