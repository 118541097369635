<template>
  <div class="relative top-0">
    <agile
      :nav-buttons="false"
      :autoplay-speed="delay"
      :dots="false"
      :speed="2500"
      fade
      pause-on-hover
      pause-on-dots-hover
      autoplay
    >
      <div class="slide" v-for="(slide, idx) in slides" :key="idx">
        <img class="object-cover w-screen h-screen" :src="slide.preview" />
        <div
          v-if="slide.title"
          class="absolute bottom-0 left-0 z-10 mb-20 ml-8"
        >
          <h1 class="inline-block text-4xl uppercase text-white bg-red px-2">
            {{ slide.title }}
          </h1>
          <h5 v-if="slide.teaser" class="text-xl bg-red mt-1 px-2 text-white">
            {{ slide.teaser }}
          </h5>
        </div>
      </div>
    </agile>
    <div class="absolute z-10 bottom-0 mb-2 flex justify-center w-screen">
      <a @click="scroll_down_to_index" href="#rental-index" class="pb-2">
        <svg
          id="down"
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="50"
          viewBox="0 0 24 24"
        >
          <path
            d="M24 12c0-6.627-5.373-12-12-12s-12 5.373-12 12 5.373 12 12 12 12-5.373 12-12zm-18.005-1.568l1.415-1.414 4.59 4.574 4.579-4.574 1.416 1.414-5.995 5.988-6.005-5.988z"
          />
        </svg>
      </a>
    </div>
  </div>
</template>

<script>
import { VueAgile } from "vue-agile"
import scrollIt from "../utilities/scrollit"

export default {
  props: ["slides", "delay"],
  data() {
    return {
      counter: 0
    }
  },
  methods: {
    scroll_down_to_index() {
      scrollIt(
        document.querySelector("#rental-index"),
        500,
        "easeOutQuad",
        () => console.log(`Just finished scrolling to ${window.pageYOffset}px`)
      )
    }
  },
  components: {
    Agile: VueAgile
  }
}
</script>

<style lang="scss" scoped>
svg#down path {
  fill: #e1001e;
}
.agile {
  .agile__slides {
    position: absolute;

    .slide {
      // width: 100vw;
      height: 100vh;
      object-fit: cover;

      h1,
      h5 {
        opacity: 0;
      }

      &.agile__slide--active {
        // width: 100vw !important;
        h1,
        h5 {
          opacity: 1;
        }
      }
    }
  }
}
</style>
