<template>
  <div class="container w-full p-2 sm:p-4">
    <form @submit.prevent="check_field_and_send">
      <div class="mietanfrage flex flex-wrap -mx-3">
        <div>
          <h1 class="text-1xl">Mietanfrage zu</h1>
          <h1 class="mt-4 text-3xl">{{ product.title }}</h1>
          <p class="mt-4">{{ product.teaser }}</p>
        </div>
        <div class="m-auto w-1/1 sm:w-1/2 my-6">
          <h1 class="text-right block text-5xl">
            <span v-if="product.price_prefix" class="text-sm">{{
              product.price_prefix
            }}</span>
            <span v-if="product.price">{{ product.price }} €</span>
            <span v-if="product.price_suffix" class="text-sm">{{
              product.price_suffix
            }}</span>
          </h1>
          <h5
            v-if="product.price_with_tax <= 0"
            class="text-right block text-sm"
          >
            excl. Mwst.
          </h5>
          <h5 v-else class="text-right block text-sm">incl. Mwst.</h5>
        </div>
      </div>

      <div class="flex flex-wrap mt-4 -ml-3 items-center justify-between">
        <div class="w-1/1 md:w-1/2">
          <label class="block uppercase tracking-wide text-xs font-bold mb-2"
            >NACHNAME</label
          >
          <input
            v-model="customer_form.lastname"
            v-autofocus
            ref="lastname"
            type="text"
            required
            placeholder="NACHNAME"
            class="shadow appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            :class="{ 'border-red': customer_form.lastname <= '' }"
          />
        </div>
        <div class="w-1/1 md:w-1/2">
          <label class="block uppercase tracking-wide text-xs font-bold mb-2"
            >VORNAME</label
          >
          <input
            v-model="customer_form.firstname"
            type="text"
            required
            placeholder="VORNAME"
            class="shadow appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            :class="{ 'border-red': customer_form.firstname <= '' }"
          />
        </div>
      </div>

      <div class="flex flex-wrap mt-4 -ml-3 items-center justify-between">
        <div class="w-1/1 md:w-1/2">
          <label class="block uppercase tracking-wide text-xs font-bold mb-2"
            >FIRMA</label
          >
          <input
            v-model="customer_form.company"
            type="text"
            placeholder="FIRMA"
            class="shadow appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            :class="{ 'border-red': false }"
          />
        </div>
        <div class="w-1/1 md:w-1/2">
          <label class="block uppercase tracking-wide text-xs font-bold mb-2"
            >TELEFON</label
          >
          <input
            v-model="customer_form.telefon"
            type="text"
            required
            placeholder="TELEFON oder MOBIL"
            class="shadow appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            :class="{ 'border-red': customer_form.telefon <= '' }"
          />
        </div>
      </div>

      <div class="flex flex-wrap mt-4 -ml-3 items-center justify-between">
        <div class="w-1/1 md:w-1/2">
          <label class="block uppercase tracking-wide text-xs font-bold mb-2"
            >EMAIL</label
          >
          <input
            v-model="customer_form.email"
            type="email"
            required
            placeholder="EMAIL"
            class="shadow appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            :class="{ 'border-red': customer_form.email <= '' }"
          />
        </div>
        <div class="w-1/1 md:w-1/2">
          <label class="block uppercase tracking-wide text-xs font-bold mb-2"
            >STRASSE</label
          >
          <input
            v-model="customer_form.street"
            type="text"
            placeholder="STRASSE"
            class="shadow appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            :class="{ 'border-red': false }"
          />
        </div>
      </div>

      <div class="flex flex-wrap mt-4 -ml-3 items-center justify-between">
        <div class="w-1/1 md:w-1/2">
          <label class="block uppercase tracking-wide text-xs font-bold mb-2"
            >PLZ</label
          >
          <input
            v-model="customer_form.zipcode"
            type="text"
            placeholder="Postleitzahl"
            class="shadow appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            :class="{ 'border-red': false }"
          />
        </div>
        <div class="w-1/1 md:w-1/2">
          <label class="block uppercase tracking-wide text-xs font-bold mb-2"
            >ORT</label
          >
          <input
            v-model="customer_form.city"
            type="text"
            placeholder="STADT"
            class="shadow appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            :class="{ 'border-red': false }"
          />
        </div>
      </div>

      <div class="flex flex-wrap mt-4 -ml-3 items-center justify-between">
        <div class="w-full">
          <label class="block uppercase tracking-wide text-xs font-bold mb-2"
            >DIE GEWÜNSCHTE MIETDAUER</label
          >
          <template v-for="(rental_period, idx) in rental_periods">
            <input
              :id="'rental_period_' + rental_period.id"
              type="radio"
              :key="idx"
              :value="rental_period.id"
              v-model="customer_form.rental_period"
              class="ml-1"
            />
            <label :for="'rental_period_' + rental_period.id">{{
              rental_period.title
            }}</label>
          </template>
        </div>
      </div>

      <div
        v-if="
          product.is_vehicle ||
            product.with_insurance ||
            product.with_coupling_device
        "
        class="flex flex-wrap mt-4 -ml-3 items-center justify-between"
      >
        <div class="w-full">
          <label class="block uppercase tracking-wide text-xs font-bold mb-2"
            >Zusätzliche Option(en)</label
          >
          <template v-if="product.with_coupling_device">
            <input
              id="with_coupling_device"
              type="checkbox"
              :value="1"
              v-model="customer_form.with_coupling_device"
              class="ml-1"
            />
            <label for="with_coupling_device">Mit Anhängekupplung</label>
          </template>
          <template v-if="product.with_insurance">
            <input
              id="with_insurance"
              type="checkbox"
              :value="1"
              v-model="customer_form.with_insurance"
              class="mr-1 ml-1"
            />
            <label for="with_insurance">Mit Haftpflichtversicherung</label>
          </template>
        </div>
      </div>

      <div class="flex flex-wrap mt-4 -ml-3 items-center justify-between">
        <div class="w-full">
          <label class="block uppercase tracking-wide text-xs font-bold mb-2"
            >ANMERKUNGEN</label
          >
          <textarea
            v-model="customer_form.comment"
            rows="4"
            class="shadow appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
            :class="{ 'border-red': false }"
          />
        </div>
      </div>

      <div class="flex flex-wrap mt-4 -ml-3 items-center justify-between">
        <div class="w-1/1 md:w-1/2"></div>
        <div class="w-1/1 md:w-1/2">
          <button
            type="submit"
            class="mietanfrage-send shadow float-right rounded py-1 px-3 bg-red text-white"
          >
            ANFRAGE SENDEN
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"

export default {
  props: ["product"],
  data() {
    return {
      customer_form: {
        product_id: null,
        rental_period: 0,
        lastname: "",
        firstname: "",
        company: "",
        telefon: "",
        email: "",
        street: "",
        zipcode: "",
        city: "",
        with_insurance: false,
        with_coupling_device: false,
        comment: "",
      },
      required_fields: ["lastname", "firstname", "email", "telefon"],
    }
  },
  computed: {
    ...mapState(["rental_periods"]),
  },
  methods: {
    ...mapActions(["send_order"]),
    check_field_and_send() {
      this.$gtm.trackEvent({
        event: null,
        category: "DisplayProductSendForm",
        label: this.product.title,
        value: this.product.price,
      })
      this.customer_form.product_id = this.product.id
      this.send_order(this.customer_form)
    },
  },
  mounted() {
    console.log("order-form mounted")
    this.$gtm.trackEvent({
      event: null,
      category: "DisplayProductOpenForm",
      label: this.product.title,
      value: this.product.price,
    })
  },
}
</script>
