<template>
  <div
    class="container w-full"
    :class="layout == 'vonblon' ? 'bg-vonblon-blue' : 'bg-vonblon-white'"
  >
    <div class="product-detail flex flex-wrap">
      <div class="w-1/1 md:w-1/2 md:mx-2">
        <h1 class="text-1xl">Produkt Details zu</h1>
        <h1 class="mt-4 text-3xl leading-none">{{ product.title }}</h1>
        <p class="mt-4" v-html="product.description"></p>
      </div>
      <div class="flex flex-wrap w-1/1 md:w-1/2 mt-5">
        <div class="w-1/1">
          <h1 class="text-right block text-5xl">
            <span
              v-if="product.price > 0 && product.price_prefix"
              class="text-sm"
              >{{ product.price_prefix }}</span
            >
            <span v-if="product.price > 0">{{ product.price }} €</span>
            <span
              v-if="product.price > 0 && product.price_suffix"
              class="text-sm"
              >{{ product.price_suffix }}</span
            >
          </h1>

          <h5
            v-if="product.price > 0 && product.price_with_tax <= 0"
            class="text-right block text-sm"
          >
            excl. Mwst.
          </h5>
          <h5
            v-if="product.price > 0 && product.price_with_tax > 0"
            class="text-right block text-sm"
          >
            incl. Mwst.
          </h5>

          <div class="md:mt-4 float-right block md:w-56 md:h-56 clear">
            <img
              :src="product.thumb"
              class="align-right object-cover w-full"
              alt
            />
          </div>
        </div>
        <div class="w-1/1 mt-6 text-right">
          <button
            @click.prevent="$emit('show-order-form')"
            class="mietanfrage-request py-1 text-white px-3 bg-red text-xl rounded"
          >
            Anfragen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["layout", "product"],
}
</script>
